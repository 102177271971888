<template>
  <div class="register">
    <div class="register-title">
      <img src="../../assets/img/logo02.png" alt="" />
    </div>
    <div class="register-wrap">
      <div class="wrap-title">
        <p>{{ $t("歡迎加入panggame!") }}</p>
        <p>{{ $t("为使用游戏，请按照以下的格式进行注册流程") }}</p>
      </div>
      <Checkboxes></Checkboxes>
      <div class="wrap-box">
        <ul class="u-box1 clearfix">
          <li v-for="(item, index) in tabtit" :key="index" @click="tab(index)">
            <span :class="{ regactive: index == num }">{{ item }}</span>
          </li>
        </ul>
        <ul class="u-box2">
          <li v-show="num == 0">
            <Phone></Phone>
          </li>
          <li v-show="num == 1">
            <Email></Email>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Checkboxes from "./Checkboxes.vue";
import Email from "./Email.vue";
import Phone from "./Phone.vue";
export default {
  name: "register",
  components: {
    Checkboxes,
    Email,
    Phone,
  },
  data() {
    return {
      fullWidth: 0,
      num: 0,
      tabtit: [this.$t("手機註冊"), this.$t("信箱註冊")],
    };
  },
  methods: {
    handleResize() {
      this.fullWidth = document.documentElement.clientWidth;
    },
    tab(index) {
      this.num = index;
    },
  },
  mounted() {
    this.handleResize();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss">
.register {
  max-width: 1000px;
  margin: 100px auto;
  min-height: 800px;
  .ant-checkbox + span {
    font-size: 20px;
  }
  .register-title {
    text-align: center;
    margin-bottom: 50px;
  }
  .register-wrap {
    padding: 30px;
    text-align: left;
    min-height: 800px;
    margin: 0 auto;
    max-width: 1000px;
    @media only screen and (max-width: 900px) {
      margin: 0 20px;
      margin-bottom: 100px;
    }
    .wrap-title {
      font-size: 22px;
      color: #222;
      margin: 0 0 30px 0;
      p {
        margin-bottom: 10px;
      }
    }
    .wrap-box {
      .u-box1 {
        margin-bottom: 30px;
        li {
          float: left;
          color: #b3b3b3;
          width: 220px;
          height: 35px;
          line-height: 35px;
          font-size: 24px;
          text-align: left;
          margin-right: 20px;
          padding-bottom: 20px;
          cursor: pointer;
        }
      }
    }
  }
  .regactive {
    border-bottom: 3px solid #c6a86c !important;
    color: #c6a86c !important;
    padding-bottom: 20px !important;
  }
}
</style>
