<template>
  <div class="checkboxes">
    <a-checkbox :checked="isAge" @change="onAge"
      >만 15세 이상입니다.</a-checkbox
    >
    <a-checkbox :checked="isTerms" @change="onTerms">
      <span
        ><a href="https://accountkr.panggame.com/#/policy?tab=0" target="_blank"
          >이용약관</a
        ></span
      >
      동의 (필수)
    </a-checkbox>
    <a-checkbox :checked="isPrivacy" @change="onPrivacy"
      ><span
        ><a href="https://accountkr.panggame.com/#/policy?tab=1" target="_blank"
          >개인정보 수집 및 이용</a
        ></span
      >
      동의 (필수)</a-checkbox
    >
    <table id="collection">
      <tr>
        <td>수집·이용 목적</td>
        <td>이용자 식별, 회원 서비스 제공</td>
      </tr>
      <tr>
        <td>수집·이용 항목</td>
        <td>휴대폰번호, 이메일, 비밀번호, 닉네임</td>
      </tr>
      <tr>
        <td>보유기간</td>
        <td><span class="highlight">회원 탈퇴 시</span></td>
      </tr>
    </table>
    <p class="note">
      *개인정보보호법에 따라 개인정보 수집·이용 동의를 거부하실 수 있으며, 거부
      시에는 팡스카이 회원제 서비스 이용이 어렵습니다.
    </p>
  </div>
</template>

<script>
import bus from "./bus";
export default {
  data() {
    return {
      isAge: true, //Age 15+
      isTerms: false,
      isPrivacy: false,
    };
  },
  methods: {
    onAge() {
      this.isAge = !this.isAge; //send true, then receive as 1; otherwise 0
      bus.$emit("age", this.isAge);
    },
    onTerms() {
      this.isTerms = !this.isTerms; //send true, then receive as 1; otherwise 0
      bus.$emit("terms", this.isTerms);
    },
    onPrivacy() {
      this.isPrivacy = !this.isPrivacy; //send true, then receive as 1; otherwise 0
      bus.$emit("privacy", this.isPrivacy);
    },
  },
};
</script>

<style lang="scss">
.checkboxes {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-bottom: 30px;
  width: 100%;
  .ant-checkbox-wrapper {
    margin: 0 0 15px 0;
    color: #222;
    span {
      span {
        color: #1890ff;
      }
    }
  }
  .ant-checkbox-inner {
    width: 30px;
    height: 30px;
  }
  .ant-checkbox-inner::after {
    width: 10px;
    height: 20px;
  }
}
#collection {
  margin-top: 15px;
  border-collapse: collapse;
  width: 100%;
  max-width: 1000px;
  td {
    background: #c2c2c229;
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 24px;
    .highlight {
      color: #000;
      text-decoration: underline;
      text-underline-offset: 5px;
      font-size: 30px;
    }
  }
}
.note {
  margin-top: 15px;
  color: #302d3d;
  // font-size: 26px;
}
@media only screen and (min-width: 768px) {
  .note {
    font-size: 22px;
  }
}
</style>
