<template>
  <div class="register-email">
    <div class="email-container clearfix">
      <div class="num-container">
        <div class="top">
          <div class="email-box">
            <a-input
              @input="changeInputEmail"
              v-model="email"
              placeholder="XXX@gmail.com"
              type="text"
            />
            <button v-show="countshow" @click="getCode()">
              <span v-if="fullWidth > 900">{{ this.$t("獲取驗證碼") }}</span>
              <span v-else>{{ this.$t("驗證碼") }}</span>
            </button>
            <button
              style="background: #d9d9d9; color: #333"
              v-show="!countshow"
            >
              <span class="count">{{ count }} s</span>
            </button>
          </div>
        </div>
        <div class="redcolor">{{ emailerr }}</div>
      </div>
      <div class="code-container">
        <div>
          <a-input
            v-model="code"
            :placeholder="this.$t('請輸入驗證碼')"
            type="text"
          />
        </div>
        <div @click="tips()" style="cursor: pointer" class="redcolor">
          {{ $t("還沒有收到驗證信件？") }}
        </div>
      </div>
    </div>
    <div class="sure">
      <div class="radio">
        <div>
          <a-checkbox :checked="check1" @change="onChangecheck1">
            {{
              this.$t("同時使用 手機號碼 作為登入帳號，取消勾選可自訂登入帳號")
            }}
          </a-checkbox>
        </div>
      </div>
    </div>
    <div class="pwd" v-show="check1 == false">
      <div>
        <a-input
          @input="changeInput"
          v-model="userEmail"
          :placeholder="this.$t('請輸入自訂賬號')"
          type="text"
          name=""
        />
      </div>
      <div class="redcolor">{{ numerr }}</div>
    </div>
    <div class="pwd">
      <div>
        <a-input-password
          v-model="emailPwd"
          :placeholder="$t('密碼')"
          type="password"
          name=""
        />
      </div>
      <div class="redcolor">{{ pwd1err }}</div>
    </div>
    <div class="pwd">
      <div>
        <a-input-password
          v-model="emailPwdAgain"
          :placeholder="$t('確認密碼')"
          type="password"
          name=""
        />
      </div>
      <div class="redcolor">{{ pwd2err }}</div>
    </div>
    <!-- <div class="sure">
      <div class="radio">
        <div>
          <a-checkbox :checked="check2" @change="onChangecheck2">
            <span>
              {{ this.$t("同意接受") }}
              <router-link to="/userpage/user">{{
                this.$t("《會員服務條款》")
              }}</router-link>
              .<router-link to="/userpage/user">{{
                this.$t("《個人隱私條款》")
              }}</router-link>
            </span>
          </a-checkbox>
        </div>
      </div>
    </div>
    <div class="sure">
      <div class="radio">
        <div>
          <a-checkbox :checked="check3" @change="onChangecheck3">
            {{ this.$t("同意接受 Panggame 消息及廣告訊息") }}
          </a-checkbox>
        </div>
      </div>
    </div> -->
    <Radios @sendEmail="onRadio1" @sendSMS="onRadio2"></Radios>
    <div class="sure-btn">
      <p @click="getSure()">
        <loading v-show="isLoad"></loading>
        <span style="display: inline-block; margin-left: 10px">{{
          this.$t("確認")
        }}</span>
      </p>
    </div>
    <dialogBar
      ref="ModalRejectclick"
      v-model="sendVal"
      type="default"
      title="我是标题"
      content="我是内容"
      v-on:cancel="clickSure()"
      @danger="clickDanger()"
      @confirm="clickConfirm()"
    >
      <span slot="content">
        <div class="tips-sure-cont">
          <div class="sure-title">{{ this.$t("還沒有收到驗證信件？") }}</div>
          <p>1.{{ this.$t("網路會有一定延遲，請等幾分鐘後再去看看。") }}</p>
          <p>
            2.{{
              this.$t(
                "可能被信箱過濾，請到廣告信件、垃圾信件或者相關信件歸檔中查看。"
              )
            }}
          </p>
          <p>
            3.{{
              this.$t(
                "請確認一下Email地址是否拼寫錯誤，您可以用正確的信箱地址重新註冊。"
              )
            }}
          </p>
        </div>
      </span>
    </dialogBar>
  </div>
</template>

<script>
import bus from "./bus";
import Radios from "./Radios.vue";
import request from "../../api/request";
import validate from "../../utils/validate";
import { encrypt } from "../../utils/jsencrypt";
import loading from "../../components/Loading";
import dialogBar from "../../components/Dialog";
export default {
  name: "email",
  components: {
    loading,
    dialogBar,
    Radios,
  },
  data() {
    return {
      ageOver15: true,
      agreeTerms: false,
      agreePrivacy: false,
      isLoad: false,
      countshow: true,
      sendVal: false,
      count: "",
      timer: null,
      fullWidth: 0,
      check1: true,
      check2: false,
      check3: false,
      email: "",
      userEmail: "",
      emailPwd: "",
      emailPwdAgain: "",
      code: "",
      emailerr: "",
      codeerr: "",
      pwd1err: "",
      pwd2err: "",
      numerr: this.$t("賬號第一位必須為英文字母，最多 32 個字元"),
      ms: 3000,
      timeID: "",
    };
  },
  methods: {
    tips() {
      this.$refs.ModalRejectclick.showMask = true;
    },
    clickSure() {
      this.$refs.ModalRejectclick.showMask = false;
    },
    changeInput(e) {
      this.userEmail = e.target.value;
    },
    changeInputEmail(e) {
      this.email = e.target.value;
    },
    handleResize() {
      this.fullWidth = document.documentElement.clientWidth;
    },
    tab(index) {
      this.num = index;
    },
    onChangecheck1(e) {
      this.check1 = e.target.checked;
      // console.log(`checked = ${e.target.checked}`);
    },
    onChangecheck2(e) {
      this.check2 = e.target.checked;
      // console.log(`checked = ${e.target.checked}`);
    },
    onChangecheck3(e) {
      this.check3 = e.target.checked;
      // console.log(`checked = ${e.target.checked}`);
    },
    onRadio1(e) {
      this.receiveEmail = e;
    },
    onRadio2(e) {
      this.receiveSMS = e;
    },
    getCode() {
      let _this = this;
      let res = validate.email(_this.email);
      if (res) {
        _this.emailerr = _this.$t(res);
        return;
      } else {
        _this.emailerr = "";
        const TIME_COUNT = 60;
        if (!_this.timer) {
          _this.count = TIME_COUNT;
          _this.countshow = false;
          _this.timer = setInterval(() => {
            if (_this.count > 0 && _this.count <= TIME_COUNT) {
              _this.count--;
            } else {
              _this.countshow = true;
              clearInterval(_this.timer);
              _this.timer = null;
            }
          }, 1000);
        }
        let data = {
          email: _this.email,
          verify_source: 2,
          verify_type: 1,
        };
        request.post("/verify/send", data).then(function (res) {
          if (res.status == 200) {
            _this.$message.success(_this.$t("發送成功"));
          } else {
            _this.codeerr = res.detail;
          }
        });
      }
    },
    cx(type) {
      let _this = this;
      if (type == 0) {
        let data = {
          username: _this.userEmail,
        };
        request.post("/register/unique", data).then(function (res) {
          if (res.status == 200 || res.status == 201) {
            console.log("ok");
          } else {
            _this.$message.error(res.detail);
            return;
          }
        });
      }
      if (type == 1) {
        let data = {
          email: _this.email,
        };
        request.post("/register/unique", data).then(function (res) {
          if (res.status == 200 || res.status == 201) {
            console.log("ok");
          } else {
            _this.$message.error(res.detail);
            return;
          }
        });
      }
    },
    getSure() {
      let _this = this;
      let username = "";
      if (_this.check1 == true) {
        username = _this.email;
      }
      if (_this.userEmail != "" && _this.check1 == false) {
        username = _this.userEmail;
      }
      let res1 = validate.code(_this.code);
      let res2 = validate.username(username);
      let res3 = validate.password(_this.emailPwd);
      let res4 = validate.password(_this.emailPwdAgain);

      if (res1) {
        _this.codeerr = _this.$t(res1);
        return;
      } else {
        _this.codeerr = "";
      }
      if (_this.check1 == false) {
        if (res2) {
          _this.numerr = _this.$t(res2);
          return;
        } else {
          _this.numerr = "";
        }
      }
      if (res3) {
        _this.pwd1err = _this.$t(res3);
        return;
      } else {
        _this.pwd1err = "";
      }
      if (res4) {
        _this.pwd2err = _this.$t(res4);
        return;
      } else {
        _this.pwd2err = "";
      }
      if (_this.emailPwd != _this.emailPwdAgain) {
        _this.pwd2err = _this.$t("兩次輸入的密碼並不相符，請重新輸入");
        return;
      } else {
        _this.pwd2err = "";
      }
      // if (_this.check2 == false) {
      //   _this.$message.warning(_this.$t("請勾選同意接受條款"));
      //   return;
      // }
      if (_this.agreeTerms == false || _this.agreePrivacy == false) {
        _this.$message.warning(_this.$t("請勾選同意接受條款"));
        return;
      }
      let data = {
        email: _this.email,
        username: username,
        password: encrypt(_this.emailPwdAgain),
        code: _this.code,
        //ads: _this.check3,  //remove
        age_15: _this.ageOver15, //require new model, true or false
        by_email: _this.receiveEmail, //require new model, 0 or 1
        by_sms: _this.receiveSMS, //require new model, 0 or 1
        utm_source: _this.$route.query.utm_source,
        utm_campaign: _this.$route.query.utm_campaign,
        utm_medium: _this.$route.query.utm_medium,
        client_id: _this.client_id,
      };
      _this.isLoad = true;
      request.post("/register", data).then(function (res) {
        if (res.status == 200 || res.status == 201) {
          _this.$message.success(_this.$t("註冊成功"));
          window.localStorage.setItem("token", res.data.data.access_token);
          window.localStorage.setItem(
            "user_info",
            JSON.stringify(res.data.user)
          );
          _this.isLoad = false;
          if (_this.client_id && _this.return_url) _this.oauth2Redirect();
          else
            setTimeout(function () {
              _this.$router.push({
                name: "login",
              });
            }, 2000);
        } else {
          _this.isLoad = false;
          _this.$message.error(res.detail);
        }
      });
    },
    oauth2Redirect() {
      request
        .get("/login/oauth2", {
          response_type: "code",
          client_id: this.client_id,
          redirect_uri: this.return_url,
        })
        .then(function (res) {
          if (res.status == 200) {
            window.location.href = res.data.url;
          }
        });
    },
  },
  mounted() {
    this.handleResize();
    bus.$on("age", (data) => {
      this.ageOver15 = data;
    });
    bus.$on("terms", (data) => {
      this.agreeTerms = data;
    });
    bus.$on("privacy", (data) => {
      this.agreePrivacy = data;
    });
    if (this.$route.query.client_id && this.$route.query.return_url) {
      this.client_id = this.$route.query.client_id;
      this.return_url = this.$route.query.return_url;
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.handleResize);
  },
  //   watch: {
  //       userEmail(newValue, oldValue) {
  //           if (newValue !== oldValue) {
  //               // 数据变化了，执行逻辑，这里做好防抖。
  //               this.ms = 1000;
  //               clearTimeout(this.timeID);
  //               this.timeID = setTimeout(() => {
  //               this.cx(0)
  //               }, this.ms)
  //           }
  //       },
  //       email(newValue, oldValue) {
  //           if (newValue !== oldValue) {
  //               // 数据变化了，执行逻辑，这里做好防抖。
  //               this.ms = 1000;
  //               clearTimeout(this.timeID);
  //               this.timeID = setTimeout(() => {
  //               this.cx(1)
  //               }, this.ms)
  //           }
  //       }
  // }
};
</script>

<style lang="scss">
.register-email {
  input {
    font-size: 22px;
  }
  .ant-input-suffix {
    display: none;
  }
  .ant-checkbox-inner {
    width: 30px;
    height: 30px;
  }
  .ant-checkbox-inner::after {
    width: 10px;
    height: 20px;
  }
  .email-container {
    .num-container {
      float: left;
      margin-right: 20px;
      width: calc(70% - 20px);
      @media only screen and (max-width: 900px) {
        width: 100%;
      }
      .top {
        display: flex;
        select {
          width: 30%;
          margin-right: 20px;
          font-weight: 300;
          color: #999;
          border: 1px solid #d9d9d9;
          background: transparent;
          height: 50px;
          padding: 0 10px;
          option {
            border: none;
            background: transparent;
          }
        }
        .email-box {
          display: inline-block;
          border: 1px solid #d9d9d9;
          width: calc(100% - 20px);
          input {
            width: 70%;
            height: 50px;
            line-height: 50px;
            color: #222;
            border: none;
            outline: none;
            background: transparent;
            font-family: Arial, Helvetica, sans-serif;
          }
          button {
            width: 30%;
            height: 40px;
            line-height: 40px;
            font-size: 18px;
            background-color: #c6a86c;
            border-radius: 40px;
            position: relative;
            top: 3px;
            @media only screen and (max-width: 900px) {
              top: -3px;
            }
          }
        }
      }
    }
    .code-container {
      float: left;
      width: 30%;
      input {
        height: 50px;
        line-height: 50px;
        color: #222;
        border: 1px solid #d9d9d9;
      }
    }
  }
}
.sure {
  margin: 30px 0;
}
.radio {
  display: flex;
}
.pwd {
  margin: 40px 0 0 0;
}
.pwd input {
  width: 100%;
  height: 50px;
  line-height: 50px;
  color: #222;
  border: 1px solid #d9d9d9;
}
.sure-btn {
  margin-top: 50px;
}
.sure-btn p {
  text-align: center;
  width: 330px;
  height: 50px;
  line-height: 50px;
  margin: 0 auto;
  font-size: 26px;
  background: #1890ff;
  color: #fff;
  cursor: pointer;
}
.tips-sure-cont .sure-title {
  text-align: center;
  font-size: 30px;
  margin: 50px 0;
}
.tips-sure-cont p {
  margin: 20px 40px;
  font-size: 22px;
  color: #b3b3b3;
}
</style>
