<template>
  <div class="radios">
    <h3 class="title">[선택] 광고성 정보 수신 동의 (이메일, SMS/MMS)</h3>
    <table id="notification">
      <tr>
        <td>수신 동의 목적</td>
        <td>
          회원의 이메일, 휴대폰을 이용하여 뉴스, 이벤트, 소식, 설문, 광고 정보를
          전송
        </td>
      </tr>
      <tr>
        <td>수신 동의 항목<br />및 동의</td>
        <td>
          <div class="area">
            <table id="send">
              <tr>
                <td>
                  <span style="color: #000; font-weight: bold">이메일</span>
                </td>
                <td class="last-col">
                  <a-radio-group
                    name="radioGroup"
                    :default-value="agreeEmail"
                    @change="onChange1"
                  >
                    <a-radio :value="1"> 동의함 </a-radio>
                    <a-radio :value="0"> 동의하지 않음 </a-radio></a-radio-group
                  >
                </td>
              </tr>
              <tr class="last-row">
                <td>
                  <span style="color: #000; font-weight: bold">SMS/MMS</span>
                </td>
                <td class="last-col">
                  <a-radio-group
                    name="radioGroup"
                    :default-value="agreeSMS"
                    @change="onChange2"
                  >
                    <a-radio :value="1"> 동의함 </a-radio>
                    <a-radio :value="0"> 동의하지 않음 </a-radio></a-radio-group
                  >
                </td>
              </tr>
            </table>
          </div>
        </td>
      </tr>
      <tr>
        <td>보유기간</td>
        <td><span class="highlight">회원탈퇴 또는 동의 철회 시</span></td>
      </tr>
      <tr>
        <td>수신동의<br />거부· 철회방법</td>
        <td>
          본 동의는 거부하실 수 있습니다. 다만 거부 시 동의를 통해 제공 가능한
          각종 혜택, 이벤트 안내를 받아 보실 수 없습니다. 본 수신동의를
          철회하고자 할 경우에는 마이페이지에서 수신여부를 변경하실 수 있습니다.
        </td>
      </tr>
    </table>
    <!-- {{ agreeEmail }}
    {{ agreeSMS }} -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      agreeEmail: 0,
      agreeSMS: 0,
    };
  },
  methods: {
    onChange1(e) {
      this.agreeEmail = e.target.value;
      this.$emit("sendEmail", this.agreeEmail);
    },
    onChange2(e) {
      this.agreeSMS = e.target.value;
      this.$emit("sendSMS", this.agreeSMS);
    },
  },
};
</script>

<style lang="scss" scoped>
.radios {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}
#notification {
  margin-top: 15px;
  border-collapse: collapse;
  width: 100%;
  max-width: 1000px;
  table-layout: fixed;
  tr {
    td:first-child {
      width: 25%;
    }
  }
  td {
    background: #c2c2c229;
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 24px;
    .highlight {
      color: #000;
      text-decoration: underline;
      text-underline-offset: 5px;
      font-size: 30px;
    }
  }
  .area {
    margin: -8px !important;
    #send {
      width: 100%;
      border-collapse: collapse;
      border: none;
      tr {
        td:first-child {
          width: 24%;
        }
      }
      td {
        background: #fff !important;
        border-top: 0;
        border-right: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        border-left: 0;
      }
      tr.last-row td {
        border-bottom: 0;
      }

      tr td.last-col {
        border-right: 0;
        .ant-radio-wrapper {
          font-size: 24px;
          color: #3d3d3d;
          margin-right: 25px;
        }
      }
    }
    ::v-deep.ant-radio-inner {
      width: 28px;
      height: 28px;
      &::after {
        width: 20px;
        height: 20px;
      }
    }
  }
}
@media only screen and (min-width: 768px) {
  .title {
    font-size: 26px;
  }
  #notification {
    td {
      padding: 20px;
    }
    .area {
      margin: -20px !important;
      #send {
        tr td.last-col {
          .ant-radio-wrapper {
            margin-right: 60px;
          }
        }
      }
    }
  }
}
</style>
