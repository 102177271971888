var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"radios"},[_c('h3',{staticClass:"title"},[_vm._v("[선택] 광고성 정보 수신 동의 (이메일, SMS/MMS)")]),_c('table',{attrs:{"id":"notification"}},[_vm._m(0),_c('tr',[_vm._m(1),_c('td',[_c('div',{staticClass:"area"},[_c('table',{attrs:{"id":"send"}},[_c('tr',[_vm._m(2),_c('td',{staticClass:"last-col"},[_c('a-radio-group',{attrs:{"name":"radioGroup","default-value":_vm.agreeEmail},on:{"change":_vm.onChange1}},[_c('a-radio',{attrs:{"value":1}},[_vm._v(" 동의함 ")]),_c('a-radio',{attrs:{"value":0}},[_vm._v(" 동의하지 않음 ")])],1)],1)]),_c('tr',{staticClass:"last-row"},[_vm._m(3),_c('td',{staticClass:"last-col"},[_c('a-radio-group',{attrs:{"name":"radioGroup","default-value":_vm.agreeSMS},on:{"change":_vm.onChange2}},[_c('a-radio',{attrs:{"value":1}},[_vm._v(" 동의함 ")]),_c('a-radio',{attrs:{"value":0}},[_vm._v(" 동의하지 않음 ")])],1)],1)])])])])]),_vm._m(4),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("수신 동의 목적")]),_c('td',[_vm._v(" 회원의 이메일, 휴대폰을 이용하여 뉴스, 이벤트, 소식, 설문, 광고 정보를 전송 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_vm._v("수신 동의 항목"),_c('br'),_vm._v("및 동의")])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('span',{staticStyle:{"color":"#000","font-weight":"bold"}},[_vm._v("이메일")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('span',{staticStyle:{"color":"#000","font-weight":"bold"}},[_vm._v("SMS/MMS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("보유기간")]),_c('td',[_c('span',{staticClass:"highlight"},[_vm._v("회원탈퇴 또는 동의 철회 시")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_vm._v("수신동의"),_c('br'),_vm._v("거부· 철회방법")]),_c('td',[_vm._v(" 본 동의는 거부하실 수 있습니다. 다만 거부 시 동의를 통해 제공 가능한 각종 혜택, 이벤트 안내를 받아 보실 수 없습니다. 본 수신동의를 철회하고자 할 경우에는 마이페이지에서 수신여부를 변경하실 수 있습니다. ")])])
}]

export { render, staticRenderFns }