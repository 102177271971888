<template>
  <div class="register-phone">
    <!-- {{ ageOver15 }}
    {{ agreeTerms }}
    {{ agreePrivacy }} -->
    <div class="phone-container clearfix">
      <div class="num-container">
        <div class="top">
          <select v-model="aaa" name="num">
            <option value="82">KR+82</option>
            <!-- <option value="886">TW+886</option>
            <option value="852">HK+852</option>
            <option value="853">MC+853</option>
            <option value="66">66</option> -->
          </select>
          <div class="phone-box">
            <a-input
              @input="changeInputPhone"
              v-model="phone"
              placeholder="01XXXXXXXXX"
              type="text"
            />
            <button v-show="countshow" @click="getCode()">
              <span v-if="fullWidth > 900">{{ this.$t("獲取驗證碼") }}</span>
              <span v-else>{{ this.$t("驗證碼") }}</span>
            </button>
            <button
              style="background: #d9d9d9; color: #333"
              v-show="!countshow"
            >
              <span class="count">{{ count }} s</span>
            </button>
          </div>
        </div>
        <div style="font-size: 12px" class="redcolor">{{ phoneerr }}</div>
      </div>
      <div class="code-container">
        <div>
          <a-input
            v-model="code"
            :placeholder="this.$t('請輸入驗證碼')"
            type="text"
          />
        </div>
        <div style="font-size: 12px" class="redcolor">{{ codeerr }}</div>
      </div>
    </div>
    <div class="sure">
      <div class="radio">
        <div>
          <a-checkbox :checked="check1" @change="onChangecheck1">
            {{
              this.$t("同時使用 手機號碼 作為登入帳號，取消勾選可自訂登入帳號")
            }}
          </a-checkbox>
        </div>
      </div>
    </div>
    <div class="pwd" v-show="check1 == false">
      <div>
        <a-input
          @input="changeInput"
          v-model="userPhone"
          :placeholder="this.$t('請輸入自訂賬號')"
          type="text"
          name=""
        />
      </div>
      <div style="font-size: 12px" class="redcolor">{{ numerr }}</div>
    </div>
    <div class="pwd">
      <div>
        <a-input-password
          v-model="phonePwd"
          :placeholder="$t('密碼')"
          type="password"
          name=""
        />
      </div>
      <div style="font-size: 12px" class="redcolor">{{ pwd1err }}</div>
    </div>
    <div class="pwd">
      <div>
        <a-input-password
          v-model="phonePwdAgain"
          :placeholder="$t('確認密碼')"
          type="password"
          name=""
        />
      </div>
      <div style="font-size: 12px" class="redcolor">{{ pwd2err }}</div>
    </div>
    <!-- <div class="sure">
      <div class="radio">
        <div>
          <a-checkbox @change="onChangecheck2">
            <span>
              {{ this.$t("同意接受") }}
              <router-link to="/userpage/user">{{
                this.$t("《會員服務條款》")
              }}</router-link>
              .<router-link to="/userpage/user">{{
                this.$t("《個人隱私條款》")
              }}</router-link>
            </span>
          </a-checkbox>
        </div>
      </div>
    </div>
    <div class="sure">
      <div class="radio">
        <div>
          <a-checkbox @change="onChangecheck3">
            {{ this.$t("同意接受 Panggame 消息及廣告訊息") }}
          </a-checkbox>
        </div>
      </div>
    </div> -->
    <Radios @sendEmail="onRadio1" @sendSMS="onRadio2"></Radios>
    <div class="sure-btn">
      <p @click="getSure()">
        <loading v-show="isLoad"></loading>
        <span style="display: inline-block; margin-left: 10px">{{
          this.$t("確認")
        }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import bus from "./bus";
import Radios from "./Radios.vue";
import request from "../../api/request";
import validate from "../../utils/validate";
import { encrypt } from "../../utils/jsencrypt";
import loading from "../../components/Loading";
export default {
  name: "phone",
  components: {
    loading,
    Radios,
  },
  data() {
    return {
      ageOver15: true,
      agreeTerms: false,
      agreePrivacy: false,
      receiveEmail: 0,
      receiveSMS: 0,
      isLoad: false,
      countshow: true,
      count: "",
      timer: null,
      fullWidth: 0,
      check1: true, //no change
      check2: false, //remodel to agreeTerms
      check3: false, //remodel to agreePrivacy
      aaa: "82",
      phone: "",
      code: "",
      userPhone: "",
      phonePwd: "",
      phonePwdAgain: "",
      codeerr: "",
      phoneerr: "",
      pwd1err: "",
      pwd2err: "",
      numerr: this.$t("賬號第一位必須為英文字母，最多 32 個字元"),
      ms: 3000,
      timeID: "",
    };
  },
  methods: {
    changeInput(e) {
      this.userPhone = e.target.value;
    },
    changeInputPhone(e) {
      this.phone = e.target.value;
    },
    handleResize() {
      this.fullWidth = document.documentElement.clientWidth;
    },
    tab(index) {
      this.num = index;
    },
    onChangecheck1(e) {
      this.check1 = e.target.checked;
    },
    onChangecheck2(e) {
      this.check2 = e.target.checked;
    },
    onChangecheck3(e) {
      this.check3 = e.target.checked;
    },
    onRadio1(e) {
      this.receiveEmail = e;
    },
    onRadio2(e) {
      this.receiveSMS = e;
    },
    getCode() {
      let _this = this;
      const TIME_COUNT = 60;
      let res = validate.phone(_this.phone);
      if (res) {
        _this.phoneerr = _this.$t(res);
        return;
      } else {
        _this.phoneerr = "";
        if (!_this.timer) {
          _this.count = TIME_COUNT;
          _this.countshow = false;
          _this.timer = setInterval(() => {
            if (_this.count > 0 && _this.count <= TIME_COUNT) {
              _this.count--;
            } else {
              _this.countshow = true;
              clearInterval(_this.timer);
              _this.timer = null;
            }
          }, 1000);
        }
        let data = {
          phone: _this.phone,
          areacode: _this.aaa,
          verify_source: 1,
          verify_type: 1,
        };
        request.post("/verify/send", data).then(function (res) {
          if (res.status == 200) {
            _this.$message.success(_this.$t("發送成功"));
          } else {
            _this.codeerr = res.detail;
          }
        });
      }
    },
    cx(type) {
      if (type == 1) {
        let _this = this;
        let data = {
          phone: _this.phone,
          areacode: _this.aaa,
        };
        request.post("/register/unique", data).then(function (res) {
          if (res.status == 200 || res.status == 201) {
            console.log("ok");
          } else {
            return;
          }
        });
      }
      if (type == 0) {
        let _this = this;
        let data = {
          username: _this.userPhone,
        };
        request.post("/register/unique", data).then(function (res) {
          if (res.status == 200 || res.status == 201) {
            console.log("ok");
          } else {
            return;
          }
        });
      }
    },
    getSure() {
      let _this = this;
      let username = "";
      if (!_this.phone || !/^01\d{9}$/.test(_this.phone)) {
        _this.$message.warning(_this.$t("请正确输入您的手机号码，然后重试"));
        return;
      }
      if (_this.check1 == true) {
        username = _this.phone;
      }
      if (_this.userPhone != "" && _this.check1 == false) {
        username = _this.userPhone;
      }
      let res1 = validate.code(_this.code);
      let res2 = validate.username(username);
      let res3 = validate.password(_this.phonePwd);
      let res4 = validate.password(_this.phonePwdAgain);
      if (res1) {
        _this.codeerr = _this.$t(res1);
        return;
      } else {
        _this.codeerr = "";
      }
      if (_this.check1 == false) {
        if (res2) {
          _this.numerr = _this.$t(res2);
          return;
        } else {
          _this.numerr = "";
        }
      }
      if (res3) {
        _this.pwd1err = _this.$t(res3);
        return;
      } else {
        _this.pwd1err = "";
      }
      if (res4) {
        _this.pwd2err = _this.$t(res4);
        return;
      } else {
        _this.pwd2err = "";
      }
      if (_this.phonePwd != _this.phonePwdAgain) {
        _this.pwd2err = _this.$t("兩次輸入的密碼並不相符，請重新輸入");
        return;
      } else {
        _this.pwd2err = "";
      }
      // if (_this.check2 == false) {
      //   _this.$message.warning(_this.$t("請勾選同意接受條款"));
      //   return;
      // }
      if (_this.agreeTerms == false || _this.agreePrivacy == false) {
        _this.$message.warning(_this.$t("請勾選同意接受條款"));
        return;
      }
      let data = {
        phone: _this.phone,
        username: username,
        password: encrypt(_this.phonePwd),
        areacode: _this.aaa,
        code: _this.code,
        //ads: _this.check3,  //remove
        age_15: _this.ageOver15, //require new model, true or false
        by_email: _this.receiveEmail, //require new model, 0 or 1
        by_sms: _this.receiveSMS, //require new model, 0 or 1
        utm_source: _this.$route.query.utm_source,
        utm_campaign: _this.$route.query.utm_campaign,
        utm_medium: _this.$route.query.utm_medium,
        client_id: _this.client_id,
      };
      _this.isLoad = true;
      request.post("/register", data).then(function (res) {
        if (res.status == 200 || res.status == 201) {
          _this.$message.success(_this.$t("註冊成功"));
          window.localStorage.setItem("token", res.data.data.access_token);
          window.localStorage.setItem(
            "user_info",
            JSON.stringify(res.data.user)
          );
          _this.isLoad = false;
          if (_this.client_id && _this.return_url) _this.oauth2Redirect();
          else
            setTimeout(function () {
              _this.$router.push({
                name: "login",
              });
            }, 3000);
        } else {
          _this.isLoad = false;
          _this.$message.error(res.detail);
        }
      });
    },
    oauth2Redirect() {
      request
        .get("/login/oauth2", {
          response_type: "code",
          client_id: this.client_id,
          redirect_uri: this.return_url,
        })
        .then(function (res) {
          if (res.status == 200) {
            window.location.href = res.data.url;
          }
        });
    },
  },
  mounted() {
    this.handleResize();
    bus.$on("age", (data) => {
      this.ageOver15 = data;
    });
    bus.$on("terms", (data) => {
      this.agreeTerms = data;
    });
    bus.$on("privacy", (data) => {
      this.agreePrivacy = data;
    });
    if (this.$route.query.client_id && this.$route.query.return_url) {
      this.client_id = this.$route.query.client_id;
      this.return_url = this.$route.query.return_url;
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.handleResize);
  },
  // watch: {
  // userPhone(newValue, oldValue) {
  //   if (newValue !== oldValue) {
  //     // 数据变化了，执行逻辑，这里做好防抖。
  //     this.ms = 1000;
  //     clearTimeout(this.timeID);
  //     this.timeID = setTimeout(() => {
  //       this.cx(0);
  //     }, this.ms);
  //   }
  // },
  // phone(newValue, oldValue) {
  //   if (newValue !== oldValue) {
  //     // 数据变化了，执行逻辑，这里做好防抖。
  //     this.ms = 1000;
  //     clearTimeout(this.timeID);
  //     this.timeID = setTimeout(() => {
  //       this.cx(1);
  //     }, this.ms);
  //   }
  // },
  // },
};
</script>

<style lang="scss">
.register-phone {
  input {
    font-size: 22px;
  }
  .ant-input-suffix {
    display: none;
  }
  .ant-checkbox-inner {
    width: 30px;
    height: 30px;
    margin-top: -8px;
    margin-right: 0;
  }
  .ant-checkbox-inner::after {
    width: 10px;
    height: 20px;
  }
  .phone-container {
    .num-container {
      float: left;
      margin-right: 20px;
      width: calc(70% - 20px);
      @media only screen and (max-width: 900px) {
        width: 100%;
      }
      .top {
        display: flex;
        select {
          width: 30%;
          margin-right: 20px;
          font-weight: 300;
          color: #999;
          border: 1px solid #d9d9d9;
          background: transparent;
          height: 51px;
          padding: 0 8px;
          font-size: 20px;
          option {
            border: none;
            background: transparent;
          }
        }
        .phone-box {
          display: inline-block;
          border: 1px solid #d9d9d9;
          padding: 0 8px;
          width: calc(70% - 20px);
          input {
            width: 70%;
            height: 50px;
            line-height: 50px;
            color: #222;
            border: none;
            outline: none;
            background: transparent;
            font-family: Arial, Helvetica, sans-serif;
          }
          button {
            width: 30%;
            height: 40px;
            line-height: 40px;
            font-size: 18px;
            background-color: #c6a86c;
            border-radius: 40px;
            position: relative;
            top: 3px;
            @media only screen and (max-width: 900px) {
              top: -3px;
            }
          }
        }
      }
    }
    .code-container {
      float: left;
      width: 30%;
      input {
        height: 50px;
        line-height: 50px;
        color: #222;
        border: 1px solid #d9d9d9;
      }
    }
  }
}
.sure {
  margin: 30px 0;
}
.radio {
  display: flex;
}
.pwd {
  margin: 20px 0 0 0;
}
.pwd input {
  width: 100%;
  height: 50px;
  line-height: 50px;
  color: #222;
  border: 1px solid #d9d9d9;
}
.sure-btn {
  margin-top: 50px;
}
.sure-btn p {
  text-align: center;
  width: 330px;
  height: 50px;
  line-height: 50px;
  margin: 0 auto;
  font-size: 26px;
  background: #1890ff;
  color: #fff;
  cursor: pointer;
}
</style>
